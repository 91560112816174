/*eslint-disable*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container, Typography, Stack, TextField } from "@mui/material";
import { listHomepageDetails, resetHomepageData } from "store/actions";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import Page from "../components/Page";
import moment from "moment";

// ----------------------------------------------------------------------

export default function DashboardApp() {
    const dispatch = useDispatch();
    const [from, setFrom] = useState(moment().subtract(1, "months").format("YYYY-MM-DD"));
    const [to, setTo] = useState(moment().format("YYYY-MM-DD"));

    const {
        homepageReports,
        profile: { name },
    } = useSelector(({ homepage: { reports }, merchant: { profile } }) => ({
        homepageReports: reports,
        profile,
    }));

    useEffect(() => {
        setTo(moment(to).set({ hour: "23", minute: "59" }).format("YYYY-MM-DD HH:mm"));

        dispatch(resetHomepageData());
        dispatch(listHomepageDetails({ startDate: moment(from).unix(), endDate: moment(to).unix() }));
    }, [from, to]);

    return (
        <Page title="Dashboard">
            <Container maxWidth="xl">
                <Stack direction="row" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">Hi, {name ? name : "Welcome back"}</Typography>
                    <Stack direction={{ xs: "column", md: "row", lg: "row" }} spacing={1}>
                        <TextField
                            size="small"
                            label="from"
                            type="date"
                            defaultValue={from}
                            onChange={(e) => setFrom(e.target.value)}
                            inputProps={{ max: moment(to).subtract(1, "days").format("YYYY-MM-DD") }}
                        />
                        <TextField
                            size="small"
                            label="to"
                            type="date"
                            defaultValue={to}
                            onChange={(e) => setTo(e.target.value)}
                            inputProps={{ max: moment().format("YYYY-MM-DD") }}
                        />
                    </Stack>
                </Stack>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <AppWidgetSummary
                            title="Total Transactions"
                            color="secondary"
                            total={homepageReports.total_txns ?? "0"}
                            sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: "20px" }}
                        />
                    </Grid>
                </Grid>
                <br />

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="subtitle1">Solana chain</Typography>
                        <hr style={{ display: "block", height: "1px", border: 0, borderTop: "1px solid #ccc" }} /> <br />
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total Solana"
                                subtitle="Ethereum chain"
                                total={
                                    homepageReports.settled_amounts
                                        ? homepageReports.settled_amounts.filter((e) => e.network === "solana")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="info"
                                icon={"cryptocurrency:sol"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total USDC"
                                subtitle="Solana chain"
                                total={
                                    homepageReports.settled_amounts
                                        ? homepageReports.settled_amounts.filter((e) => e.network === "sol_usdc")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="secondary"
                                icon={"cryptocurrency:usdc"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total USDT"
                                subtitle="Solana chain"
                                total={
                                    homepageReports.settled_amounts
                                        ? homepageReports.settled_amounts.filter((e) => e.network === "sol_usdt")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="secondary"
                                icon={"cryptocurrency:usdt"}
                            />
                        </Stack>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="subtitle1">Ethereum chain</Typography>
                        <hr style={{ display: "block", height: "1px", border: 0, borderTop: "1px solid #ccc" }} /> <br />
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total Ethereum"
                                subtitle="Ethereum chain"
                                total={homepageReports.total_ethereum ?? "0"}
                                color="dark"
                                icon={"cryptocurrency:eth"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total Avalanche"
                                subtitle="Ethereum chain"
                                total={homepageReports.total_avalanche ?? "0"}
                                color="error"
                                icon={"cryptocurrency:avax"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total Polygon"
                                subtitle="Ethereum chain"
                                total={homepageReports.total_polygon ?? "0"}
                                color="secondary"
                                icon={"cryptocurrency:matic"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total USDC"
                                subtitle="Ethereum chain"
                                total={
                                    homepageReports.settled_amounts
                                        ? homepageReports.settled_amounts.filter((e) => e.network === "eth_usdc")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="secondary"
                                icon={"cryptocurrency:usdc"}
                            />
                        </Stack>
                    </Grid> */}
                </Grid>
                <br />

                {/* <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5">Unsettled Balances</Typography>
                        <hr style={{ display: "block", height: "1px", border: 0, borderTop: "1px solid #ccc" }} />
                        <br />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="subtitle1">Solana chain</Typography>
                        <hr style={{ display: "block", height: "1px", border: 0, borderTop: "1px solid #ccc" }} />
                        <br />
                        <AppWidgetSummary
                            title="Total Solana"
                            subtitle="Solana chain"
                            total={
                                homepageReports?.unsettled_amounts
                                    ? homepageReports.unsettled_amounts.filter((e) => e.network === "solana")[0]?.amount ??
                                      "0"
                                    : "0"
                            }
                            color="info"
                            icon={"cryptocurrency:sol"}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="subtitle1">Ethereum chain</Typography>
                        <hr style={{ display: "block", height: "1px", border: 0, borderTop: "1px solid #ccc" }} /> <br />
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total Ethereum"
                                subtitle="Ethereum chain"
                                total={
                                    homepageReports?.unsettled_amounts
                                        ? homepageReports.unsettled_amounts.filter((e) => e.network === "ethereum")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="dark"
                                icon={"cryptocurrency:eth"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total Avalanche"
                                subtitle="Ethereum chain"
                                total={
                                    homepageReports?.unsettled_amounts
                                        ? homepageReports.unsettled_amounts.filter((e) => e.network === "avalanche")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="error"
                                icon={"cryptocurrency:avax"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total Polygon"
                                subtitle="Ethereum chain"
                                total={
                                    homepageReports?.unsettled_amounts
                                        ? homepageReports.unsettled_amounts.filter((e) => e.network === "polygon")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="secondary"
                                icon={"cryptocurrency:matic"}
                            />
                            <AppWidgetSummary
                                sx={{ width: "100%" }}
                                title="Total USDC"
                                subtitle="Ethereum chain"
                                total={
                                    homepageReports?.unsettled_amounts
                                        ? homepageReports.unsettled_amounts.filter((e) => e.network === "eth_usdc")[0]
                                              ?.amount ?? "0"
                                        : "0"
                                }
                                color="secondary"
                                icon={"cryptocurrency:usdc"}
                            />
                        </Stack>
                    </Grid>
                </Grid> */}
            </Container>
        </Page>
    );
}
