import { combineReducers } from "redux";
import { LOGOUT } from "../types";

import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import sidebarReducer from "./sidebarReducer";
import modalReducer from "./modalReducer";
import plinksReducer from "./plinksReducer";
import homepageReducer from "./homepageReducer";
import merchantReducer from "./merchantReducer";
import settlementsReducer from "./settlementsReducer";
import paymentPagesReducer from "./paymentPagesReducer";
import transactionsReducer from "./transactionsReducer ";

const appReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    sidebar: sidebarReducer,
    modal: modalReducer,
    plinks: plinksReducer,
    homepage: homepageReducer,
    merchant: merchantReducer,
    settlements: settlementsReducer,
    paymentPages: paymentPagesReducer,
    transactions: transactionsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
