/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Button,
} from "@mui/material";
// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import { resetTxnsData, listAllBusinessTxns } from "store/actions";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: "txn_id", label: "TXN ID", alignRight: false },
    { id: "amount", label: "Amount", alignRight: false },
    { id: "created_at", label: "Created At", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "txn_for", label: "Txn For", alignRight: false },
    { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.url?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function BusinessTransactions() {
    const [order, setOrder] = useState("desc");
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState("index");
    const [filterName, setFilterName] = useState("");

    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const { txnsData, showViewMore } = useSelector(({ transactions: { list, showViewMore } }) => ({
        txnsData: list,
        showViewMore,
    }));

    useEffect(() => {
        dispatch(resetTxnsData());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllBusinessTxns());
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = txnsData.map((n) => n.url);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const filteredUsers = applySortFilter(txnsData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <Page title="Transactions">
            <Container>
                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={txnsData.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.map((row, index) => {
                                        const { txn_id, created_at, status, amount, currency, txn_for } = row;
                                        const isItemSelected = selected.indexOf(txn_id) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                                // onClick={() => navigate(`/dashboard/txn-details/${txn_id}`)}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, url)} /> */}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="subtitle2" noWrap>
                                                        {txn_id}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography noWrap>
                                                        {amount} {currency.toUpperCase()}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography noWrap>
                                                        {moment.unix(created_at).format("DD-MM-YYYY HH:mm")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant="ghost"
                                                        color={
                                                            (status === "failed" && "error") ||
                                                            (status === "success" && "success") ||
                                                            (status === "pending" && "warning") ||
                                                            "default"
                                                        }
                                                    >
                                                        {sentenceCase(status)}
                                                    </Label>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="subtitle2" noWrap>
                                                        {txn_for}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">{/* <UserMoreMenu id={plink_id}/> */}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    {showViewMore && (
                        <Stack justifyContent="center">
                            <Button onClick={fetchMoreData}>Show more</Button>
                        </Stack>
                    )}
                </Card>
            </Container>
        </Page>
    );
}
