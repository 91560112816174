import React, { useState } from "react";
import { Tab, Tabs, Stack, Typography, Container } from "@mui/material";

import TabOne from "./business-txns";
import TabTwo from "./wallet-txns";

function Transactions() {
    const [tab, setTab] = useState(1);

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Transactions
                </Typography>
            </Stack>
            <Stack>
                <Tabs value={tab || 1} onChange={handleTabs} centered>
                    <Tab value={1} label="Business Account" />
                    <Tab value={2} label="Wallet Txns" />
                </Tabs>
            </Stack>
            <br />
            {tab === 1 ? <TabOne /> : <TabTwo />}
        </Container>
    );
}

export default Transactions;
