export const FETCH_ALL_BUSINESS_TRANSACTIONS = `query ($pageSize: Int, $pageNumber: Int, $status: String) {
    list_all_business_transactions(page_size: $pageSize, page_number: $pageNumber, status: $status) {
      message
      data {
        _id
        txn_id
        amount
        currency
        created_at
        status
        chain_network
        txn_for
      }
      hasMore
    }
  }
  `;

export const FETCH_ALL_WALLET_TRANSACTIONS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_wallet_transactions(page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      txn_id
      txn_signature
      type
      amount
      status
      chain_network
      currency
      created_at
      from_user_wid
      to_user_wid
      from_uid {
        _id
        name
        email
      }
      to_uid {
        _id
        name
        email
      }
    }
    hasMore
  }
}
`;
