import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import PaymentLinks from "pages/payment-links";
import Settlements from "pages/settlements";
import MyAccount from "pages/my-account";
import PaymentPages from "pages/payment-pages";
import PaymentLinkDetails from "pages/payment-links/paymentLinkDetails";
import SettlementDetails from "pages/settlements/settlementDetails";
import CustomPaymentPage from "pages/payment-pages/customPaymentPage";
import PaymentPageDetails from "pages/payment-pages/paymentPageDetails";
import Transactions from "pages/transactions";

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: "/dashboard",
            element: <DashboardLayout />,
            children: [
                { path: "app", element: <DashboardApp /> },
                { path: "payment-links", element: <PaymentLinks /> },
                { path: "plink-details/:id", element: <PaymentLinkDetails /> },
                { path: "account", element: <MyAccount /> },
                { path: "settlements", element: <Settlements /> },
                { path: "settlement-details/:id", element: <SettlementDetails /> },
                { path: "payment-pages", element: <PaymentPages /> },
                { path: "payment-pages/custom", element: <CustomPaymentPage /> },
                { path: "page-details/:id", element: <PaymentPageDetails /> },
                { path: "transactions", element: <Transactions /> },
            ],
        },
        {
            path: "login",
            element: <Login />,
        },
        {
            path: "register",
            element: <Register />,
        },
        {
            path: "/",
            element: <LogoOnlyLayout />,
            children: [
                { path: "/", element: <Navigate to="/login" /> },
                { path: "404", element: <NotFound /> },
                { path: "*", element: <Navigate to="/404" /> },
            ],
        },
        {
            path: "*",
            element: <Navigate to="/404" replace />,
        },
    ]);
}
