import { post } from "services";
import { FETCH_ALL_BUSINESS_TRANSACTIONS, FETCH_ALL_WALLET_TRANSACTIONS } from "graphql";
import {
    TOGGLE_LOADING,
    LIST_ALL_BUSINESS_TRANSACTIONS,
    LIST_ALL_WALLET_TRANSACTIONS,
    RESET_TRANSACTIONS_DATA,
} from "../types";

export const listAllBusinessTxns = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const { pageSize, pageNumber, list } = getState().transactions;

        let apiResponse = await post("", {
            query: FETCH_ALL_BUSINESS_TRANSACTIONS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                status: requestData?.status ?? "",
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_business_transactions: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_BUSINESS_TRANSACTIONS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllWalletTxns = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const { pageSize, pageNumber, list } = getState().transactions;

        let apiResponse = await post("", {
            query: FETCH_ALL_WALLET_TRANSACTIONS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                // status: requestData?.status ?? "",
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_wallet_transactions: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_WALLET_TRANSACTIONS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetTxnsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_TRANSACTIONS_DATA });
    };
};
