export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LIST_PLINKS_DATA = "LIST_PLINKS_DATA";
export const RESET_PLINKS_LIST_DATA = "RESET_PLINKS_LIST_DATA";
export const LIST_PLINK_DETAILS_DATA = "LIST_PLINK_DETAILS_DATA";
export const RESET_PLINK_DETAILS_DATA = "RESET_PLINK_DETAILS_DATA";

export const LIST_HOMEPAGE_REPORTS = "LIST_HOMEPAGE_REPORTS";
export const RESET_HOMEPAGE_DATA = "RESET_HOMEPAGE_DATA";

export const LIST_MERCHANT_PROFILE = "LIST_MERCHANT_PROFILE";
export const RESET_MERCHANT_PROFILE = "RESET_MERCHANT_PROFILE";

export const LIST_SETTLEMENTS_DATA = "LIST_SETTLEMENTS_DATA";
export const RESET_SETTLEMENTS_LIST_DATA = "RESET_SETTLEMENTS_LIST_DATA";
export const LIST_SETTLEMENT_DETAILS_DATA = "LIST_SETTLEMENT_DETAILS_DATA";
export const RESET_SETTLEMENT_DETAILS_DATA = "RESET_SETTLEMENT_DETAILS_DATA";

export const LIST_API_KEY_DATA = "LIST_API_KEY_DATA";
export const RESET_API_KEY_DATA = "RESET_API_KEY_DATA";

export const LIST_ALL_PAYMENT_PAGES_DATA = "LIST_ALL_PAYMENT_PAGES_DATA";
export const RESET_PAYMENT_PAGES_DATA = "RESET_PAYMENT_PAGES_DATA";

export const LIST_PAGE_DETAILS_DATA = "LIST_PAGE_DETAILS_DATA";
export const RESET_PAGE_DETAILS_DATA = "RESET_PAGE_DETAILS_DATA";

export const LIST_ALL_PAGE_PAYMENTS_DATA = "LIST_ALL_PAGE_PAYMENTS_DATA";
export const RESET_PAGE_PAYMENTS_DATA = "RESET_PAGE_PAYMENTS_DATA";

export const LIST_ALL_BUSINESS_TRANSACTIONS = "LIST_ALL_BUSINESS_TRANSACTIONS";
export const LIST_ALL_WALLET_TRANSACTIONS = "LIST_ALL_WALLET_TRANSACTIONS";
export const RESET_TRANSACTIONS_DATA = "RESET_TRANSACTIONS_DATA";
