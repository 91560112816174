/*eslint-disable*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// material
import { Grid, Typography, Box, Avatar, TextField, Button, Stack, InputAdornment, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import { Icon } from "@iconify/react";
import Page from "../../components/Page";
// import { toast } from 'react-toastify';
// mock
import account from "_mock/account";
import { sentenceCase } from "change-case";
import { listMerchantProfile, resetMerchantProfile, updateBlockchainAccounts } from "store/actions";
import UpdateWalletModalContent from "./UpdateWalletModalContent";

// ----------------------------------------------------------------------

const AccountStyle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

export default function Profile() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [address, setAddress] = useState({});
    const [network, setNetwork] = useState("");

    const { profile } = useSelector(({ merchant: { profile } }) => ({
        profile,
    }));

    useEffect(() => {
        dispatch(resetMerchantProfile());
        dispatch(listMerchantProfile());
    }, []);

    const findWallet = (network) => {
        if (profile?.blockchain_accounts) {
            let filtered = profile?.blockchain_accounts.filter((e) => e.network === network);
            return filtered.length ? filtered[0].address : "";
        }
        return "";
    };

    // const handleSaveButton = (network) => {
    //   if (address?.network != network) {
    //     return toast.warning(`Please enter ${network} address`)
    //   }
    //   if(address.network==='USDC'){
    //     address.network = 'eth_usdc'
    //   }
    //   dispatch(updateBlockchainAccounts({ blockchainAccounts: [address] }))
    // }

    const handleUpdateButton = (network) => {
        setNetwork(network);
        setAddress(findWallet(network));
        handleOpen();
    };

    const renderBlockChainAccount = (network, symbol, chain, iconColor) => {
        return (
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                    <Grid item lg={4} md={4} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {network != "solana" ? (
                                <Icon width={33} height={33} icon={`cryptocurrency:${symbol}`} color={iconColor} />
                            ) : null}
                            <Stack>
                                {network === "solana" ? (
                                    <Stack direction="row" spacing={4} sx={{ mb: 1 }}>
                                        <Icon width={30} height={30} icon={`cryptocurrency:${symbol}`} color={iconColor} />
                                        <Icon width={30} height={30} icon={`cryptocurrency:usdc`} color={"blue"} />
                                        <Icon width={30} height={30} icon={`cryptocurrency:usdt`} color={"#55AB90"} />
                                    </Stack>
                                ) : null}
                                <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
                                    {network === "solana"
                                        ? `${sentenceCase(network)} / USDC / USDT`
                                        : sentenceCase(network)}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    {chain} chain wallet address
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item lg={8} md={8} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ height: "100%" }}>
                            <TextField
                                key={network}
                                fullWidth
                                // size='small'
                                value={address?.network === network ? address?.address : findWallet(network)}
                                id="outlined-basic"
                                placeholder="Wallet Address"
                                variant="outlined"
                                disabled
                                onChange={(e) => setAddress({ address: e.target.value, network: network })}
                            />
                            {/* <Button
                variant='contained'
                size="large"
                // disabled={findWallet(network) != ""}
                // onClick={() => handleSaveButton(network)}
                onClick={() => handleUpdateButton(network)}
              >
                Update
              </Button> */}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <Page title="My Account: Profile">
            {profile ? (
                <>
                    <Grid container spacing={2}>
                        <Grid item lg={6} xs={12}>
                            <Box sx={{ mb: 2 }}>
                                <AccountStyle>
                                    <Avatar src={account.photoURL} alt={profile?.name ?? "photoURL"} />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                            {profile.name}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                            {profile.email}
                                        </Typography>
                                    </Box>
                                </AccountStyle>
                            </Box>

                            <Box>
                                <AccountStyle sx={{ overflowWrap: "break-word", display: "block" }}>
                                    <Box>
                                        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                            Merchant id
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                            {profile.merchant_id}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                            Phone
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                            {profile.phone ? profile.phone : "-"}
                                        </Typography>
                                    </Box>
                                </AccountStyle>
                            </Box>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <Box>
                                <AccountStyle>
                                    <Box>
                                        <Typography variant="h5" sx={{ color: "text.primary" }}>
                                            Business
                                        </Typography>
                                        <br />
                                        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                            Name
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                            {profile?.business?.name ? profile?.business?.name : "-"}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                            Type
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                            {profile?.business?.type ? profile?.business?.type : "-"}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                            Website URL
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                            {profile?.website ? profile?.website : "-"}
                                        </Typography>
                                    </Box>
                                </AccountStyle>
                            </Box>
                        </Grid>

                        {/* <Box sx={{ mb: 5 }}>
              <AccountStyle>
                <Box>
                  <Typography variant="h5" sx={{ color: 'text.primary' }}>
                    Business Details
                  </Typography>
                  <br />
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Pan No
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {profile?.business_details?.pan_no ? profile?.business_details?.pan_no : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Pan Holders Name
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {profile?.business_details?.pan_holders_name ? profile?.business_details?.pan_holders_name : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Billing Label
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {profile?.business_details?.billing_label ? profile?.business_details?.billing_label : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Address
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {profile?.business_details?.address ? profile?.business_details?.address : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    City
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {profile?.business_details?.city ? profile?.business_details?.city : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    State
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {profile?.business_details?.state ? profile?.business_details?.state : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Country
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {profile?.business_details?.country ? profile?.business_details?.country : "-"}
                  </Typography>
                </Box>
              </AccountStyle>
            </Box> */}

                        <Grid item lg={12} xs={12}>
                            <Box>
                                <AccountStyle>
                                    <Box sx={{ width: "100%" }}>
                                        <Typography variant="h5" sx={{ color: "text.primary" }}>
                                            Blockchain Accounts
                                        </Typography>
                                        <br />
                                        {renderBlockChainAccount("solana", "sol", "Solana", "#6778D5")}
                                        {/* {renderBlockChainAccount("ethereum", "eth", "Ethereum", "black")}
                    {renderBlockChainAccount("avalanche", "avax", "Ethereum", "#E03F40")}
                    {renderBlockChainAccount("polygon", "matic", "Ethereum", "#7E66DE")} */}
                                        {/* {renderBlockChainAccount("usdc", "usdc", "Ethereum", "#7E66DE")} */}
                                    </Box>
                                </AccountStyle>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            ) : null}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    overflow: "scroll",
                    margin: "20px 0",
                }}
            >
                <>
                    <UpdateWalletModalContent
                        address={address}
                        network={network}
                        dispatch={dispatch}
                        handleClose={handleClose}
                    />
                </>
            </Modal>
        </Page>
    );
}
