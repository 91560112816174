// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
    {
        title: "dashboard",
        path: "/dashboard/app",
        icon: getIcon("eva:pie-chart-2-fill"),
    },
    {
        title: "payment links",
        path: "/dashboard/payment-links",
        icon: getIcon("eva:link-2-fill"),
    },
    {
        title: "payment pages",
        path: "/dashboard/payment-pages",
        icon: getIcon("dashicons:admin-page"),
    },
    {
        title: "settlements",
        path: "/dashboard/settlements",
        icon: getIcon("charm:notes"),
    },
    {
        title: "transactions",
        path: "/dashboard/transactions",
        icon: getIcon("charm:notes-tick"),
    },
    {
        title: "my account",
        path: "/dashboard/account",
        icon: getIcon("eva:person-fill"),
    },
    // {
    //   title: 'login',
    //   path: '/login',
    //   icon: getIcon('eva:lock-fill'),
    // },
    // {
    //   title: 'register',
    //   path: '/register',
    //   icon: getIcon('eva:person-add-fill'),
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon('eva:alert-triangle-fill'),
    // },
];

export default navConfig;
